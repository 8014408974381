import { useState } from 'react'
import Button from 'components/Button'

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as MegaphoneIcon } from 'assets/icons/megaphone.svg'
import { ReactComponent as LetterIcon } from 'assets/icons/love-letter.svg'
import styles from './rsvp.module.scss'

const notice = `당일 안수식 이후 저녁 식사 시간이 있습니다.
참석하실 분들은 아래 링크로
접수해주시면 감사하겠습니다.`

const bankAccount = '국민은행 439201-01-406806'
const accountHolder = '더 라스트 처치'

const Rsvp = () => {
  const [copiedId, setCopiedId] = useState<'phone' | 'bankAccount' | null>(null)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id, value } = e.target as HTMLButtonElement
    navigator.clipboard.writeText(value)
    setCopiedId(id as 'phone' | 'bankAccount')
    setTimeout(() => setCopiedId(null), 2000)
  }

  return (
    <section>
      <div className={styles.header}>
        <MegaphoneIcon />
        <h2>안내사항</h2>
      </div>
      <p className={styles.message}>{notice}</p>
      <div className={styles.buttonWrapper}>
        <Button
          text='접수하기'
          size='fullWidth'
          buttonStyle='secondary'
          link='https://docs.google.com/forms/d/e/1FAIpQLSeL0ajeUf37DjQKqYvWrrjbk7YQOPlA6fpC49473NHs5SSJzA/viewform'
        />
      </div>
      <div className={styles.header}>
        <LetterIcon className={styles.letterIcon} />
        <h2>마음 전하실 곳</h2>
      </div>
      <p className={styles.message}>
        {accountHolder}
        <br />
        {bankAccount}
      </p>
      <div className={styles.buttonWrapper}>
        <Button
          id='bankAccount'
          text={copiedId === 'bankAccount' ? '복사 완료' : '계좌번호 복사'}
          value={bankAccount}
          icon={copiedId === 'bankAccount' && <CheckIcon />}
          size='fullWidth'
          buttonStyle='secondary'
          onClick={handleClick}
        />
      </div>
    </section>
  )
}

export default Rsvp
