import { ko } from 'date-fns/locale'
import { DayPicker } from 'react-day-picker'
import styles from './calendar.module.scss'

const date = new Date(Date.UTC(2024, 8, 14, 9))
const fullDate = new Intl.DateTimeFormat('ko-KR').format(date)
const month = new Intl.DateTimeFormat('ko-KR', { month: 'long' }).format(date)
const day = new Intl.DateTimeFormat('ko-KR', { weekday: 'short' }).format(date)
const time = '오후 4시'

const Calendar = () => {
  return (
    <section className={styles.section}>
      <h2>일시</h2>
      <p className={styles.date}>
        {fullDate} ({day}) {time}
      </p>
      <div className={styles.calendar}>
        <div className={styles.month}>{month}</div>
        <DayPicker
          locale={ko}
          disableNavigation
          defaultMonth={date}
          selected={date}
          modifiersClassNames={{ selected: styles.selected }}
          styles={{
            caption: { display: 'none' },
            head_row: { height: 24, fontWeight: 500 },
            day: { padding: 8, margin: 5 },
          }}
        />
      </div>
    </section>
  )
}

export default Calendar
