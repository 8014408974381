import styles from './welcome.module.scss'

const welcomeMessage = `주님의 이름으로 문안드립니다.
주님 만나기전 세상에서 방황했고, 현재도 부족한 사람이지만 하나님의 깊은 부르심을 받았습니다. 사람을 기쁘게 하는 것이 아닌 하나님을 기쁘게 하는 그리스도의 종(엡6:6)이 되고자 합니다. 일생에 한번 뿐인 이 자리에 참석해주셔서 기도해주시고 격려해 주시면 감사드리겠습니다.`
const from = '박재영 선교사'

const Welcome = () => {
  return (
    <section className={styles.welcome}>
      <div className={styles.background}>
        <div className={styles.message}>
          <h2>초대합니다</h2>
          {welcomeMessage.split('\n').map((line) => (
            <p key={line}>{line}</p>
          ))}
          <p className={styles.from}>{from} 올림</p>
        </div>
      </div>
      {/* <img src={photo} alt='선교사 박재영, 정재유' /> */}
    </section>
  )
}

export default Welcome
