import { useEffect, useRef } from 'react'

const location = {
  lat: 37.5728842,
  lng: 127.0016066,
}

const NaverMap = () => {
  const mapElement = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!mapElement.current) return

    const position = new naver.maps.LatLng(location.lat, location.lng)

    const mapOptions: naver.maps.MapOptions = {
      center: position,
      zoom: 15,
    }
    const map = new naver.maps.Map(mapElement.current, mapOptions)
    const _ = new naver.maps.Marker({
      position,
      map,
    })
  }, [])

  return <div ref={mapElement} style={{ width: '100%', height: '100%' }} />
}

export default NaverMap
