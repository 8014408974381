import NaverMap from './NaverMap'
import kakaoIcon from 'assets/icons/kakao_map.png'
import naverIcon from 'assets/icons/naver_map.png'
import styles from './location.module.scss'

const venue = '한국기독교회관 2층 조예홀'
const address = '서울 종로구 대학로 19'
const detail = '종로5가역 하차 후 2번 출구에서 200m'

const naverMapLink = 'https://naver.me/FQVIi9ov'
const kakaoMapLink = 'https://kko.to/HaxQ-_qRih'

const Location = () => {
  return (
    <section>
      <h2>오시는 길</h2>
      <div className={styles.map}>
        <NaverMap />
      </div>
      <div className={styles.location}>
        <h3>{venue}</h3>
        <div className={styles.info}>
          <div>
            <h4>주소</h4>
            <p>{address}</p>
          </div>
          <div>
            <h4>교통안내</h4>
            <p>{detail}</p>
          </div>
        </div>
        <div className={styles.mapIcons}>
          <a href={naverMapLink} target='_blank' rel='noreferrer'>
            <img src={naverIcon} alt='네이버 지도' />
          </a>
          <a href={kakaoMapLink} target='_blank' rel='noreferrer'>
            <img src={kakaoIcon} alt='카카오 지도' />
          </a>
        </div>
      </div>
    </section>
  )
}

export default Location
