import cx from 'classnames'
import { ButtonHTMLAttributes, MouseEventHandler } from 'react'

import styles from './button.module.scss'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string
  icon?: React.ReactNode
  value?: string
  size?: 'fullWidth' | 'large' | 'medium' | 'small'
  buttonStyle?: 'primary' | 'secondary' | 'ghost'
  className?: string
  disabled?: boolean
  link?: string
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  onClick?: MouseEventHandler<HTMLButtonElement>
  props?: any
}

const Button = ({
  text,
  icon,
  value,
  size = 'medium',
  buttonStyle = 'primary',
  type = 'button',
  className,
  disabled,
  link,
  onClick,
  ...props
}: IButtonProps) => {
  if (link) {
    return (
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
        className={cx(styles.button, styles[size], styles[buttonStyle], className)}
      >
        {icon}
        {text}
      </a>
    )
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={cx(styles.button, styles[size], styles[buttonStyle], className)}
      disabled={disabled}
      onClick={onClick}
      value={value}
      {...props}
    >
      {icon}
      {text}
    </button>
  )
}

export default Button
