import backgroundTop from 'assets/images/background-top.png'
import backgroundBottom from 'assets/images/background-bottom.png'
import { ReactComponent as DividerIcon } from 'assets/icons/divider.svg'
import styles from './hero.module.scss'

const title = `목사 안수식 초청장`

const information = [
  { title: '교단', content: '대한예수교 장로회 (합동개혁)' },
  { title: '안수자', content: '박재영 선교사' },
  { title: '일시', content: '2024년 9월 14일 (토) 오후 4시' },
  { title: '장소', content: '한국기독교회관 2층 조예홀' },
]

const Hero = () => {
  return (
    <section className={styles.hero}>
      <img src={backgroundTop} alt='background' />
      <div className={styles.text}>
        <h1 style={{ animationDelay: '0.1s' }}>{title}</h1>
        <div className={styles.info} style={{ animationDelay: '0.3s' }}>
          <div>
            <h3>{information[0].title}</h3>
            <p>{information[0].content}</p>
          </div>
          <div>
            <h3>{information[1].title}</h3>
            <p>{information[1].content}</p>
          </div>
        </div>
        <div className={styles.info} style={{ animationDelay: '0.5s' }}>
          <DividerIcon className={styles.divider} />
          <div>
            <strong>{information[2].title}:</strong> {information[2].content}
          </div>
          <div>
            <strong>{information[3].title}:</strong> {information[3].content}
          </div>
        </div>
      </div>
      <img src={backgroundBottom} alt='background' />
    </section>
  )
}

export default Hero
