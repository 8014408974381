import { Route, Routes } from 'react-router-dom'

import { initializeGTM } from 'hooks/useGTM'
import Layout from 'components/Layout'
import Home from './Home'

initializeGTM()

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='/' element={<Home />} />
      </Route>
      <Route path='*' element={<div>404</div>} />
    </Routes>
  )
}

export default App
