import Rsvp from './components/Rsvp'
import Calendar from './components/Calendar'
import Hero from './components/Hero'
import Location from './components/Location'
import Welcome from './components/Welcome'
import Message from './components/Message'

const Home = () => {
  return (
    <>
      <Hero />
      <Welcome />
      <Location />
      <Calendar />
      <Rsvp />
      <Message />
    </>
  )
}

export default Home
